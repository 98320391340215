<template>
  <div class="login login-bg">
    <div class="side-img-wrapper">
      <img class="side-img" src="../../assets/banner-future.jpg" alt="">
    </div>
    <div class="login-content-wrapper">
      <div class="login-content">
        <img class="logo" src="../../assets/totem-logo.png" alt="Logo">
        <p>Please enter your email address and<br />password to log in.</p>
        <form name="login" onsubmit="return false;">
          <div class="field field-icon">
            <i class="far fa-user"></i>
            <input type="text" name="user" id="login-user" placeholder="Username" v-model="username">
          </div>
          <div class="field field-icon">
            <i class="far fa-lock"></i>
            <input type="password" name="pass" id="login-pass" placeholder="Password" v-model="password">
          </div>
          <div class="submit">
            <div class="login-button" @click="handleLogin">
              <input type="submit" value="Log In">
              <i class="far fa-long-arrow-right"></i>
            </div>
            <div class="google-login" @click="handleGoogleLogin">
              <svg viewBox="0 0 24 24" width="24" height="24" xmlns="http://www.w3.org/2000/svg">
                <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                  <path fill="#4285F4" d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"/>
                  <path fill="#34A853" d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"/>
                  <path fill="#FBBC05" d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"/>
                  <path fill="#EA4335" d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"/>
                </g>
              </svg>
            </div>
          </div>
          <router-link to="/">Return to website</router-link>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  inject: ["createNotification"],
  data() {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    queryError() {
      return this.$route.query.error || null
    }
  },
  methods: {
    handleLogin() {
      this.$store.dispatch('login', { email: this.username, password: this.password }).then(result => {
        if (result === true) {
          // Request "Save password" popup
          if (window.PasswordCredential) {
            const passwordCredential = new PasswordCredential({ id: this.username, password: this.password });
            navigator.credentials.store(passwordCredential);
          }

          // Create a notification to communicate the succesful authentication
          this.createNotification({
            type: 'confirm',
            title: 'Logged in',
            message: 'Authentication succesful!'
          })

          // No redirection required, already handled by the store action
        } else {
          console.log(`Error: ${result}`)
          
          // Display error notification with appropriate message
          let notificationMessage;
          switch (result) {
            case "auth/user-not-found":
              notificationMessage = 'No user was found with this email!'
              break;
            
            case "auth/wrong-password":
              notificationMessage = 'You entered an incorrect password!'
              break;

            case "auth/invalid-email":
              notificationMessage = 'Please enter a valid email address.'
              break;
          
            default:
              notificationMessage = `An unknown error occurred: ${result}`
          }
          this.createNotification({
            type: 'error',
            title: 'Authentication error',
            message: notificationMessage
          })
        }
      })
    },
    handleGoogleLogin() {
      this.$store.dispatch('googleLogin').then((result) => {
        if (result === true) {
          this.createNotification({
            type: 'confirm',
            title: 'Logged in',
            message: 'Authentication succesful!'
          })

          this.$router.push('/admin')
        } else {

          console.log(result)
          switch (result) {
            case "auth/popup-closed-by-user":
              this.createNotification({
                type: 'error',
                title: 'Authentication error',
                message: `You closed the sign in modal.`
              })
              break;
          
            default:
              this.createNotification({
                type: 'error',
                title: 'Authentication error',
                message: `An error occurred: ${result}`
              })
              break;
          } 
        }
      })
    },
  },
  watch: {
    queryError: {
      immediate: true,
      handler(val) {
        switch (val) {
          case null:
            break;
          
          case 'no-auth':
            this.createNotification({
              type: 'error',
              title: 'Permission denied',
              message: 'You have to be logged in to view that page.'
            })
            break;
        
          default:
            this.createNotification({
              type: 'error',
              title: 'Error',
              message: `An error occurred: ${val}`
            })
            break;
        }
      }
    },
  },
}
</script>

<style lang="scss">
.login {
  display: grid;
  grid-template-columns: 1fr 1fr;
  

  .side-img-wrapper {
    position: relative;
    width: 50vw;
    height: 100vh;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: #292835;
      opacity: .85;
    }

    .side-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-content-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column-start: 1;
    grid-row-start: 1;
  }

  &-content {
    color: #817F98;
    width: 340px;

    & > * + * {
      margin-top: 2rem;
    }

    .logo {
      width: 70px;
    }

    .field {
      position: relative;
      margin-bottom: 1rem;

      &-icon i {
        display: flex;
        align-items: center;
        position: absolute;
        left: 1.5rem;
        top: 0;
        height: 100%;
      }

      &.disabled .field-icon i {
        color: #fff;
      }
    }

    input {
      outline: none;
      color: #fff;
      font: 17px "Rubik", sans-serif;
      transition: background-color 200ms ease, border-color 200ms ease;
    }

    input[type=text], input[type=password] {
      background-color: transparent;
      border: 2px solid #817F98;
      padding: .75rem 2rem;
      padding-left: 3rem;
      border-radius: 1000px;

      &::placeholder {
        color: #817F98;
      }

      &:focus, &:hover {
        border-color: #fff;
      }
    }

    .submit {
      display: flex;
      margin-top: 2rem;

      .login-button {
        position: relative;
        width: 180px;

        input[type=submit] {
          width: 100%;
          padding: .75rem 2rem;
          background-color: var(--color-purple);
          border: none;
          border-radius: 1000px;
          text-align: left;
          cursor: pointer;

          &:hover {
            background-color: var(--color-purple-light);
          }
        }
        
        i {
          display: flex;
          align-items: center;
          position: absolute;
          top: 0;
          right: 1.5rem;
          height: 100%;
          color: #fff;
        }
      }

      .google-login {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        width: 44px;
        margin-left: 1rem;
        background-color: #fff;
        border-radius: 1000px;
        cursor: pointer;
      }
    }

    a {
      display: inline-block;
      color: #817F98;
      margin-top: 5rem;
      transition: color 200ms ease;

      &:hover, &:focus {
        color: var(--color-purple-lightest);
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .login {
    height: 100vh;
    grid-template-columns: 1fr;

    .side-img-wrapper {
      display: none;
    }
    
    .login-content {
      width: auto;
      text-align: center;
    }
  }
}
</style>